import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import "./Section.css";
import CheckBoxesGeneric from "./CheckboxesGeneric";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Notification from "../../../Shared/Notification/Notification"

const AccordianContent = (props) => {
  const token = localStorage.getItem("agentToken");
  const [productTypes, setProductTypes] = useState([]);
  const [productTypeID, setProductTypeID] = useState("");
  const [products, setProducts] = useState([]);
  const [productID, setProductID] = useState("");
  const [product, setProduct] = useState({});
  const [productDosage, setProductDosage] = useState(0);
  const [otherProductDescription, setOtherProductDescription] = useState("");

  const [weeks, setWeeks] = useState([]);
  const [numOfWeeks, setNumOfWeeks] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [choices, setChoices] = useState([]);
  const [ogDosage, setOGDosage] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const error = "'Ander' produkte is nie volledig nie";

  const getProducts = async (productTypeID) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "/api/all_products_by_productType_id/" + productTypeID,
      requestOptions
    );

    var data = await response.json();
  
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      //blah - partymaal nie in sync met db
      data = data.filter((product) => product.active === true).sort((a,b) => a.name.localeCompare(b.name));
      setProducts([...data]);
      if (data.length === 1) {
        setProductID(data[0]["id"]);
        setProduct(data[0]);
        var choice = choices.filter(
          (choice) =>
            choice.category_id === props.category_id &&
            choice.productType_id === productTypeID &&
            choice.product_id === data[0]["id"]
        );
        setProductDosage(choice[0]["dosage"]);
        setOGDosage(choice[0]["dosage"]);
        setTotalVolume(
          choice[0].dosage *
            props.plantingDetail.farmSize *
            weeks.filter(item=>item.val === true).length
        );
      }
    }
  };

  useEffect(() => {
    setNumOfWeeks(weeks.filter(Boolean).length);
    setTotalVolume(
      productDosage *
        props.plantingDetail.farmSize *
        weeks.filter(item=>item.val === true).length
    );
  }, [weeks, productDosage, props.plantingDetail.farmSize]);

  useEffect(() => {
    const getProductTypes = async (category_id) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(
        "/api/all_productTypes_by_category_id/" + category_id,
        requestOptions
      );

      const data = await response.json();
      if (!response.ok) {
        //console.log('There is an error')
      } else {
        setProductTypes([...data.sort((a,b)=>a.name.localeCompare(b.name))]);
      }
    };

    const getAllChoices = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch("/api/all_choices/", requestOptions);

      const data = await response.json();
      if (!response.ok) {
        //console.log('There is an error')
      } else {
        setChoices([...data]);
      }
    };
   
    getProductTypes(props.category_id);
    getAllChoices();
    const weeks = props.periodsPerCrops && props.periodsPerCrops.length > 0 
    ? props.periodsPerCrops.filter((item)=>item.crop_id === props.cropId).map((item, index)=>({desc: item.name, val: false}))
    : [];

    setWeeks(weeks) 
  }, [token, props.category_id]);

  const handleFirstSelection = (e) => {
    setProductID("");
    setProductTypeID(e.value);
    getProducts(e.value);  
  };

  const handleSecondSelection = (e) => {
    setProductID(e.value);
    var thisProduct = products.filter((product) => product.id === e.value);
    setProduct(thisProduct[0]);
    var choice = choices.filter(
      (choice) =>
        choice.category_id === props.category_id &&
        choice.productType_id === productTypeID &&
        choice.product_id === e.value
    );
    setProductDosage(choice[0]["dosage"]);
    setOGDosage(choice[0]["dosage"]);
  };

  const addPlanProduct = async () => {
    var choiceID = choices.filter(
      (choice) =>
        choice.category_id === props.category_id &&
        choice.productType_id === productTypeID &&
        choice.product_id === product.id
    );
   
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        planProduct: {
          plan_id: props.planDetail.id,
          dosage: productDosage,
          weeks: JSON.stringify(weeks),
          totalVolume: Math.ceil(totalVolume),
          choice_id: choiceID[0].id,
          crop_id: props.cropId,
        },
        planProductOther: {
          new: true,
          name: otherProductDescription,
        },
      }),
    };
   
    const response = await fetch("/api/create_plan_product", requestOptions);

    await response.json();
    if (!response.ok) {
      console.log('There is an error fetching data in addPlanProduct')
    } else {
      props.setSelection([]);
      props.setProductChange(!props.productChange);
    }
  };

  const flexColumnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    paddingLeft: "1%",
  };
  const totalStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "18%",
  };
  const totalDosageStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "22%",
  };
  const flexColumnDosageStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "20%",
  };
  const flexRowStyle = { display: "flex", flexDirection: "row", width: "100%" };
  const flexContainerColumnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };
  return (
    <Card style={flexContainerColumnStyle}>
      <div style={flexRowStyle}>
        <div style={flexColumnStyle}>
          <h4>Teiken</h4>
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={productTypeID}
            options={productTypes}
            onChange={handleFirstSelection}
            placeholder={"VOEG " + props.category_name + " BY"}
          />
        </div>

        {productTypeID !== "" ? (
          <>
            <div style={flexColumnStyle}>
              <h4>Produk</h4>
              <Dropdown
                style={{ width: "100%" }}
                optionLabel="name"
                optionValue="id"
                value={productID}
                options={products}
                onChange={handleSecondSelection}
                placeholder={"Kies Produk"}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {productID !== "" ? (
          <>
            <div style={flexColumnDosageStyle}>
              <h4>Dosis ({product.unit}/Ha)</h4>
              <InputText
                id="dosage"
                value={productDosage}
                onChange={(e) => {
                  setProductDosage(e.target.value);
                }}
              />
              {parseFloat(productDosage) !== ogDosage ? (
                <small id="dosage-help" className="p-error block">
                  *Dosis is aangepas. Raadpleeg die produketiket om te verseker
                  dat die dosis binne registrasie is.
                </small>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

          {productID !== "" && products.filter((item)=> item.id === productID && item.isParent).length>0? (
          <>
            <div style={flexColumnDosageStyle}>
              <h4>Produk Naam</h4>
              <InputText
                id="other-description"
                value={otherProductDescription}
                onChange={(e) => {
                  setOtherProductDescription(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        
        
          {productID !== "" ? (
          <>
            <div style={totalStyle}>
              <h5>
                Totale Volume ={" "}
                {totalVolume
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }{" "}
                {product.unit}
              </h5>
            </div>
            
          </>
        ) : (
          <></>
        )}

        {productID !== "" ? (
          <>
            <div style={totalDosageStyle}>
              <h5>
                Totale Dosis per Hektaar ={" "}
                {(totalVolume / props.plantingDetail.farmSize)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {product.unit}
              </h5>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <CheckBoxesGeneric weeks={weeks} setWeeks={setWeeks} disabled={false} />
      {numOfWeeks !== 0 && product.name !== undefined ? (
        <>
          <Button
            label={"Voeg " + product.name + " by program"}
            style={{
              width: "100%",
              marginTop: "2%",
              backgroundColor: props.buttonColor,
              borderColor: props.buttonColor,
            }}
            onClick={()=>{
              if(productID !== "" && products.filter((item)=> item.id === productID && item.isParent).length>0 && otherProductDescription.trim() === ""){
                setShowNotification(true);
              }
              else 
                addPlanProduct()
            }}
            disabled={weeks && weeks.filter(i=>i.val === true).length===0}
          ></Button>
        </>
      ) : (
        <></>
      )}
      {showNotification && <Notification message={error} close={() => setShowNotification(false)} />}
    </Card>
  );
};

export default AccordianContent;
